const mtService1 = document.createElement('script')
mtService1.async = true
mtService1.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js'

const mtService2 = document.createElement('script');
mtService2.async = true;
mtService2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js'

const fallback = (selectorName, text = 'Désactivé') => {
  const container = document.querySelector(selectorName)

  if (container) {
    container.innerHTML = `<div class="tac_activate">
      <div class="tac_float">
        ${text}
        <button type="button" class="gdprAllow" onClick="openAxeptioCookies()">
          Autoriser
        </button>
      </div>
    </div>`
  }
}

const axChoices = (choices) => {
  window.axeptioChoices = choices

  const mtScript1 = document.head.querySelector(`script[src="${mtService1.src}"]`)
  const mtScript2 = document.head.querySelector(`script[src="${mtService2.src}"]`)
  if (choices && choices.MTCaptcha) {
    if (!mtScript1 && !mtScript2) {
      document.head.append(mtService1)
      document.head.append(mtService2)
    }
  } else {
    if (mtScript1 && mtScript2) {
      document.head.removeChild(mtScript1)
      document.head.removeChild(mtScript2)
    }
    setTimeout(() => {
      fallback('#mtcaptcha-field', 'MTCaptcha est désactivé.')
    }, 200)

  }

  if (choices && choices.gmaps) {
    const container = document.querySelector('.googlemaps_embed')

    if (container) {
      const id = container.getAttribute("id")
      const width = container.getAttribute("width")
      const height = container.getAttribute("height")
      const widgetURL = "https://google.com/maps/embed?pb=" + id

      container.innerHTML = `<iframe width="${width}" height="${height}" src="${widgetURL}" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`
    }
  } else {
    fallback('.googlemaps_embed', 'Google Maps est désactivé.')
  }
}

document.addEventListener("DOMContentLoaded", function(){
  (_axcb = window._axcb || []).push(function(sdk) {
    axChoices(window.axeptioChoices || {
      MTCaptcha: true,
      gmaps: false
    })

    window.axeptioUIElmt = document.querySelector('#axeptio_overlay')
    sdk.on('cookies:complete', axChoices)

    // sdk.on('consent:saved', function(choices) {
    //   window.location.reload()
    // })
  })
})


document.addEventListener('turbolinks:render', () => {
  axChoices(window.axeptioChoices)
  document.body.append(window.axeptioUIElmt)
})
